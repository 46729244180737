// imports
@import "./scss/_variables";
@import "./scss/_mixin";
@import "./scss/responsive";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// fonts
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
// @import url("https://fonts.googleapis.com/css2?family=Knewave&display=swap");
@import url("https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css");

@font-face {
  font-family: "VAGRounded";
  src: url(./assets/fonts/VAGRounded.ttf) format("truetype");
}

@font-face {
  font-family: "Knewave";
  src: url(./assets/fonts/Knewave-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "VAGRoundedBlack";
  src: url(./assets/fonts/VAGRounded-Black.otf) format("truetype");
}

@font-face {
  font-family: "VAGRoundedBT";
  src: url(./assets/fonts/VAGRoundedStd-Bold.otf) format("truetype");
}

@font-face {
  font-family: "M-Bold";
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "DIN Round Pro";
  src: url(./assets/fonts/DINRoundPro.ttf);
}

@font-face {
  font-family: "DIN Round Pro Bold";
  src: url(./assets/fonts/DINRoundPro-Bold.ttf);
}

@font-face {
  font-family: "DIN Round Pro Black";
  src: url(./assets/fonts/DINRoundPro-Black.ttf);
}

@font-face {
  font-family: "DIN Round Pro Med";
  src: url(./assets/fonts/DINRoundPro-Medi.ttf);
}

// global
* {
  box-sizing: border-box;
}

html,
body {
  @include removeSpacing;
  font-family: "Varela Round", sans-serif;
  background-color: white;
  overflow-x: hidden;
}
.mod-image {
  width: 122px;
}
#root {
  @include desktop {
    height: 100vh;
  }
}

.dbc-title {
  font-family: "Knewave", "cursive";
  color: $main-color;
}

.desktop-only {
  display: none;

  @include desktop {
    display: block;
  }
}

.flexContainer {
  display: flex;
  flex-wrap: nowrap;
  width: 90%;
}

.fat-stroke {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/fatStroke.png);
  background-repeat: no-repeat;
  border: 1px white solid;
  background-position: center;
  height: 150px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// ---------------------> Header <-----------------------------
#header-container {
  overflow-x: hidden;
  overflow-y: visible;
}

.header {
  margin: 0 auto;
  padding: 20px 4vw;
  width: 100vw;
  height: 120px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;
  font-family: "VAGRoundedBlack";
  font-size: 14px;

  .inner-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    max-width: 1400px;
    margin: auto;

    .headerLogo {
      height: auto;
      width: 140px;

      @include desktop {
        margin-right: 50px;
        width: 150px;
      }
    }

    // desktop menu
    .desktop-menu {
      margin: 0;
      padding: 0;
      color: $main-color;
      height: 80px;

      // text
      p {
        @include flexToCenter;
        @include removeSpacing;
      }

      // top section
      .header-settings {
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 30px;
        height: 100%;
        @include removeSpacing;

        // sub menu
        .header-submenu {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .splitter-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-setting-item:hover {
              cursor: pointer;
              color: lighten($color: $main-color, $amount: 15%) !important;
            }

            .header-separator {
              border: 1px $main-color solid;
              height: 20px;
              width: 1px;
              margin: 0 10px;
              background-color: $main-color;
            }
          }

          &.button-container .free-samples {
            display: none;

            @include desktop {
              display: block;
              background-color: $main-color;
              padding: 5px 10px;
              border-radius: 500px;
              color: white;
            }
          }

          &.search-bar-container {
            position: relative;

            .search-product-input {
              display: none;
              box-shadow: none;
              outline: none;

              &.showInput {
                &::placeholder {
                  color: $main-color;
                  opacity: 1;
                }

                display: block;
                width: 200px;
                border-radius: 500px;
                border: 10px $main-color solid;
                padding: 5px 20px;
                position: absolute;
                left: -200px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
          }

          @include tablet {
            &.search-bar-container {
              order: 1;
              position: relative;

              .search-product-input {
                display: block;
                border: 1px $main-color solid;
                border-radius: 500px;
                width: 100%;
                height: 30px;
                padding: 0 0 0 20px;

                &::placeholder {
                  color: $main-color;
                  opacity: 1;
                }

                &.showInput {
                  display: block;
                  width: 100%;
                  height: 30px;
                  border: 1px $main-color solid;
                  border-radius: 500px;
                  padding: 0 0 0 20px;
                  position: static;
                  transform: translate(0, 0);
                }
              }

              .search-product-icon {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }

            &.language-container {
              order: 2;
            }

            &.auth-container {
              order: 3;
            }

            &.button-container {
              order: 4;
            }
          }

          @include desktop {
            &.search-bar-container {
              border: 2px $main-color solid;
              order: 1;
              position: relative;

              .search-product-input {
                display: block;
                border: none;
                width: 100%;
                height: 100%;
                padding: 0 0 0 60px;

                &::placeholder {
                  color: $main-color;
                  opacity: 1;
                }

                &.showInput {
                  display: block;
                  border: none;
                  width: 100%;
                  height: 100%;
                  padding: 0 0 0 60px;
                  position: static;
                  transform: translate(0, 0);
                }
              }

              .search-product-icon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translate(0, -50%);
              }
            }

            &.language-container {
              order: 2;
            }

            &.auth-container {
              order: 3;
            }

            &.button-container {
              order: 4;
            }
          }
        }

        @include tablet {
          grid-template-columns: repeat(4, auto);
        }

        @include desktop {
          grid-template-columns: repeat(4, auto);
          justify-content: end;
          column-gap: 100px;
          height: 50%;
        }
      }

      // bottom section
      .header-menu {
        display: none;

        @include desktop {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 0;
          padding: 0;
          height: 50%;
          width: 100%;

          .header-menu-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .hover-underline {
              background-color: lighten($color: $main-color, $amount: 15%);
              width: 100px;
              height: 3px;
              border-radius: 50%;
              position: absolute;
              bottom: 0;
              left: -50px;
              opacity: 0;
              transition: none;
            }

            &:hover {
              cursor: pointer;
              color: lighten($color: $main-color, $amount: 15%);

              .hover-underline {
                left: 50%;
                transform: translate(-50%, 0);
                opacity: 1;
                transition: all 150ms;
              }

              &.product-container .vinda-product-list {
                display: block;
                opacity: 1;
                font-family: "VAGRoundedBT";
                font-weight: bold;
                position: fixed;
                z-index: 3;
                top: 120px;
                background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/navdropdown_bg.png);
                background-size: cover;
                background-repeat: no-repeat;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
                min-width: 10em;
                max-width: 1000px;
                margin: 0;
                padding: 0;
                list-style: none;

                .vinda-product-item {
                  padding: 20px;
                  min-width: 260px;
                  position: relative;

                  .product-divider {
                    width: 160px;
                    height: 2px;
                    background-color: #0074cb;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                    left: 20px;
                  }

                  &:hover {
                    color: #b1f5ff;
                    background-color: #0074cb;
                  }
                }
              }
            }

            // product item dropdown
            &.product-container {
              position: relative;

              .vinda-product-list {
                display: none;
                opacity: 0;
              }
            }
          }
        }
      }

      @include desktop {
        width: 100%;
        height: 100px;
      }
    }
  }

  @include desktop {
    font-size: 18px;
    padding: 20px 2vw;
    height: 140px;
  }
}

#header-divider {
  position: fixed;
  left: 0;
  top: 118px;
  width: 100vw;
  height: auto;
  z-index: 1;
  pointer-events: none;

  @include tablet {
    top: 110px;
  }

  @include desktop {
    top: 100px;
  }
}

// ---------------------> Sidebar <-----------------------------
#sidebarOverlay {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  z-index: -98;
  display: none;
  @include removeSpacing;

  // when toggled open
  &.openSidebar {
    left: 0;
    z-index: 98;
    display: block;
  }

  @include desktop {
    display: none;
  }
}

#sidebarMenu {
  position: fixed;
  top: 0;
  right: -75%;
  width: 75%;
  height: 100vh;
  text-align: right;
  background-color: white;
  padding: 8% 8% 0 0;
  margin: 0;
  transition: all 250ms;
  opacity: 0;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 3px solid #b2b2b2;

  // when toggled open
  &.openSidebar {
    right: 0;
    opacity: 1;
  }

  // image/logo
  .sidebarMenuLogo {
    height: 100px;
    width: auto;
  }

  #sidebarMenuList {
    margin: 12px 0 0 0;
    list-style: none;
    width: 100%;
    padding: 0;

    .sidebarMenuItem {
      width: 100%;
      margin: 0;
      padding: 8px 8px 8px 0;
      background-color: inherit;
      text-align: right;
      border: none;
      box-shadow: none;
      color: $main-color;
      font-weight: bold;
      cursor: pointer;

      .sidebarSocialLogo {
        font-size: 50px;
        margin-left: 10px;
        background-color: $main-color;
        color: $background-color;
        padding: 6px;
        border-radius: 50%;
      }
    }
  }

  @include tablet {
    width: 45%;
  }

  @include desktop {
    display: none;
    overflow: hidden;
  }
}

.sidebarIconToggle {
  transition: all 0.3s;
  cursor: pointer;
  width: 24px;

  .spinner {
    transition: all 0.3s;
    height: 3px;
    width: 100%;
    // background-color: $primary-color;
    background-color: $main-color;
  }

  .horizontal {
    transition: all 0.3s;
    margin-top: 3px;

    &.openSidebar {
      transition: all 0.3s;
      opacity: 0;
    }
  }

  .diagonal.part-1 {
    transition: all 0.3s;

    &.openSidebar {
      transition: all 0.3s;
      transform: rotate(135deg);
      margin-top: 8px;
    }
  }

  .diagonal.part-2 {
    transition: all 0.3s;
    margin-top: 3px;

    &.openSidebar {
      transition: all 0.3s;
      transform: rotate(-135deg);
      margin-top: -9px;
    }
  }

  @include desktop {
    display: none;
  }
}

// ---------------------> Dashboard <-----------------------------
.dashboardProfileContainer {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 4vw;

  @include desktop {
    height: 320px;
    padding: 0 5vw;
  }

  .dashboardProfilePicContainer {
    margin: 0px 15px 0px 0px;
    width: auto;
    height: 100%;

    @include desktop {
      width: 320px;
      margin: 0px 30px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dashboardProfilePic {
      margin: auto;

      @include desktop {
        width: 260px;
        height: 260px;
      }
    }
  }

  // content-menu
  .dashboardProfileContent {
    width: 100%;
    height: auto;

    // user info, upper part
    .dashboardProfile {
      display: flex;
      width: 100%;
      height: 100%;

      @include desktop {
        height: 50%;
      }

      // left
      .user-profile-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .username,
        .user-milestone {
          margin: 0;
          padding: 0;
          font-family: "VAGRounded", sans-serif;
          font-weight: bold;
        }

        .username {
          color: $tertiary-color;

          &.mobile {
            display: block;

            @include desktop {
              display: none;
            }
          }

          &.desktop {
            display: none;

            @include desktop {
              display: block;
              font-family: "Knewave", "cursive";
            }
          }
        }

        .user-milestone {
          color: rgb(100, 100, 100);
          font-size: 12px;
        }

        .desktop-user-info {
          display: none;

          @include desktop {
            display: flex;
            align-items: center;
            margin: 5px 0 0 0;

            .img-fluid {
              width: 40px;
              height: 40px;
              margin: 0 10px 0 0;
            }

            h3 {
              color: $tertiary-color;
              margin: 0;
              padding: 0;
              font-family: "VAGRounded", sans-serif;
              font-weight: bold;
            }

            .dot-separator {
              width: 8px;
              height: 8px;
              border: 2px $tertiary-color solid;
              border-radius: 50%;
              margin: 0 10px;
              padding: 0;
              background-color: $tertiary-color;
            }
          }
        }
      }

      // right
      .user-point-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;

        .user-point-label,
        #user-point {
          margin: 0;
          padding: 0;
          font-family: "VAGRounded", sans-serif;
          font-weight: bold;
        }

        .user-point-label {
          color: $primary-color;
          font-size: 12px;

          @include desktop {
            font-size: 16px;
          }
        }

        #user-point {
          color: $main-color;
        }
      }
    }
  }
}

.dashboardProfileLevel {
  @include removeSpacing;
  color: $tertiary-color;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: "VAGRounded", sans-serif;
  text-transform: uppercase;

  @include tablet {
    font-size: 1.8rem;
  }

  @include desktop {
    font-size: 1.8rem;
  }
}

.dashboardNeverBeBackground {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/dark-blue-bg.png);

  @include desktop {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/dark-blue-desktop-bg.png);
  }
}

.dashboardNeverBeContainer {
  text-align: center;
  margin: 0;
  padding-bottom: 150px;
  position: relative;

  @include desktop {
    padding-bottom: 50px;
  }

  .smiley {
    display: none;

    @include desktop {
      display: block;
      position: absolute;
      right: 10vw;
      top: 220px;
    }
  }
}

.dashboardNeverBeTitle {
  @include removeSpacing;
  color: white;
  font-family: "Knewave", "cursive";
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 6rem;
  font-size: 1.8rem;

  @include tablet {
    font-size: 2.5rem;
    padding-top: 12rem;
  }

  @include desktop {
    font-size: 2.5rem;
    padding-top: 12rem;
  }
}

.dashboardNeverBeText {
  @include removeSpacing;
  color: white;
  font-family: "Varela Round", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  font-size: 1rem;

  @include tablet {
    font-size: 1.5rem;
  }

  @include desktop {
    font-size: 1.5rem;
  }
}

.dashboardNeverBeButtonBox {
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;

  .dashboardNeverBeButton {
    color: white;
    font-weight: bold;
    position: relative;
    // max-width: 120px;
    height: auto;
    margin: 0;
    padding: 0;
    cursor: pointer;

    .dashboardNeverBeButtonText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-65%, -50%);
      font-family: "Knewave", "cursive";

      @include desktop {
        font-size: 30px;
      }
    }

    @include desktop {
      margin: 0 20px;
    }
  }
}

.dashboardNewsStore {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/news_frame.png);
  background-position: center;
  margin: 25px 0 0 0;
  padding: 10px 10px 40px 10px;
  overflow: hidden;

  @include desktop {
    padding-left: 5vw;
    padding-right: 5vw;
    background-size: 80% 100%;
  }
}

.dashboardNewsTitle {
  @include removeSpacing;
  color: $main-color;
  font-family: "Knewave", "cursive";
  text-align: center;
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.8rem;

  @include tablet {
    padding: 2rem;
    font-size: 2.5rem;
  }

  @include desktop {
    font-size: 2.5rem;
  }
}

.modalTitle {
  @include removeSpacing;
  color: $main-color;
  font-family: "Knewave", "cursive";
  text-align: center;
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.8rem;

  @include tablet {
    padding: 2rem;
    font-size: 2.5rem;
  }

  @include desktop {
    font-size: 2.5rem;
  }
}

.modalText {
  @include removeSpacing;
  color: $tertiary-color;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: "VAGRounded", sans-serif;
  text-transform: uppercase;

  @include tablet {
    font-size: 1.8rem;
  }

  @include desktop {
    font-size: 1.8rem;
  }
}

.dashboardNewsBox {
  padding: 0 5px;
  // margin: 0 20px;
  // background-color: $warning-color;
  width: 100%;
  height: 100%;

  // margin: 0 auto;
  @include desktop {
    padding: 0 10vw;
  }
}

.detailNewsBox {
  border: 1px solid $tertiary-color;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.dashboardNewsImage {
  width: 100%;
  height: auto;
}

// ---------------------> Order Tracking <-----------------------------

//---- Order Tracking Box starts ------//
.orderTrackBox {
  padding: 1rem;

  @include desktop {
    padding: 0;
  }
}

.orderTrack {
  padding: 0 1rem;
  // border: 1px dashed #2c3e50;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}

.orderTrackStep {
  display: flex;
  height: 7rem;
}

// .orderTrackStep:last-child {
// 	overflow: hidden;
// 	height: 4rem;
// }

// .orderTrackStep:last-child .orderTrackStatus span:last-of-type {
// 	display: none;
// }

.orderTrackStatus {
  margin-right: 1.5rem;
  position: relative;

  .orderTrackStatusDot {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: #d3d3d3;

    &.pending {
      background-color: $primary-color;
    }

    &.active {
      background-color: $main-color;
    }
  }

  .orderTrackStatusLine {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background-color: #d3d3d3;

    &.pending {
      background-color: $primary-color;
    }

    &.active {
      background-color: $main-color;
    }
  }
}

.orderTrackTextStat {
  @include removeSpacing;
  color: #d3d3d3;
  font-size: 1.1rem;
  font-family: "Knewave", "cursive";

  &.pending {
    color: $primary-color;
  }

  &.active {
    color: $main-color;
  }
}

.orderTrackTextSub {
  @include removeSpacing;
  text-align: justify;
  color: #d3d3d3;
  font-family: "Varela Round", sans-serif;
  font-size: 1rem;

  &.pending {
    color: $primary-color;
  }

  &.active {
    color: $cGray;
  }
}

.orderTrack {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

//---- Order Tracking Box ends ------//

// ---------------------> Point Shop <-----------------------------

.pointShopTopButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  padding: 0 10px;

  .pointShopLongButton {
    min-width: 150px !important;
  }

  .pointShopTopButton {
    width: auto;
    height: 50px;
    min-width: 125px;
    color: $main-color;
    font-family: "Varela Round", sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    padding: 0;
    @include flexToCenter;

    &.active {
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/button-background.png);
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      color: white;
    }

    @include desktop {
      width: 300px;
      height: 100px;
      font-size: 20px;
      margin: 0 20px;
    }
  }

  @include desktop {
    justify-content: center;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pointShopTopButtonContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pointShopTopButtonContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

// ---------------------> Product Detail <-----------------------------

// ---------------------> Add To Cart <-----------------------------

.addToCartTitle {
  @include removeSpacing;
  color: $main-color;
  font-family: "Knewave", "cursive";
  text-align: center;
  font-size: 1.4rem;

  @include tablet {
    font-size: 2rem;
  }

  @include desktop {
    font-size: 2.5rem;
  }
}

.addToCartText {
  @include removeSpacing;
  color: $primary-color;
  font-family: "Varela Round", sans-serif;
  text-decoration: none;
}

.addToCartContainer {
  list-style: none;
  margin: 0;
  padding: 0 20px;

  .addToCartProductBox {
    border-bottom: 1px rgba(0, 116, 200, 0.35) solid;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 20px 0;

    // image container
    .addToCartProductImageBox {
      margin: 0;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .addToCartProductImage {
        width: 150px;
        height: auto;
      }
    }

    .addToCartProductContent {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 5px 10px;

      .addToCartProductDetail {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding: 0;

        .addToCartPoint {
          display: flex;
          align-items: center;
          color: $primary-color;
          font-family: "VAGRounded", sans-serif;
          // font-family: "Varela Round", sans-serif;
          font-weight: bold;

          img.img-fluid {
            width: 30px;
            height: 30px;
          }
        }

        .addToCartQuantityController {
          display: flex;
          align-items: center;

          .arrow-container {
            background-color: $main-color;
            color: white;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  @include desktop {
    margin: 20px 0 0 0;
    padding: 0 10vw;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.addToCartContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.addToCartContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

// ---------------------> Inbox & News <-----------------------------

.newsBox {
  padding: 1rem;

  @include desktop {
    padding-right: 16rem;
    padding-left: 16rem;
  }
}

.dashboardNewsDesc {
  white-space: pre-line;
  text-align: justify;
  color: $main-color;
  // @include desktop {
  // 	font-size: 2rem;
  // }
}

.inboxBox {
  margin: 25px auto;
  padding: 0 5vw 150px 5vw;
  max-width: 800px;
}

.inboxContainer {
  border-radius: 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.75);
  display: flex;
}

.inboxImageContainer {
  padding: 10px 5px;
  @include flexToCenter;
}

.inboxImage {
  width: 80%;
}

.inboxTextContainer {
  padding: 10px 5px;
}

.inboxTitleText {
  @include removeSpacing;
  color: $tertiary-color;
  font-size: 0.8rem;
  font-weight: 900;
  font-family: "Varela Round", sans-serif;

  @include tablet {
    font-size: 1.1rem;
  }

  @include desktop {
    font-size: 1.5rem;
  }
}

.inboxTitleDesc {
  @include removeSpacing;
  color: $tertiary-color;
  font-size: 0.6rem;
  font-weight: 200;
  font-family: "Varela Round", sans-serif;
  text-align: justify;

  @include tablet {
    font-size: 1rem;
  }

  @include desktop {
    text-align: left;
    font-size: 1.2rem;
  }
}

.inboxDateContainer {
  color: $tertiary-color;
  padding: 10px 5px;

  .inboxDate {
    font-size: 0.8rem;

    @include tablet {
      font-size: 1.1rem;
    }

    @include desktop {
      font-size: 1.5rem;
    }
  }
}

// ---------------------> User Profile <-----------------------------
.userProfileContainer {
  padding: 0 5vw;
  margin: 20px 0 0 0;

  .userProfileBox {
    border-bottom: 2px $secondary-color solid;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 0;
    padding: 0 20px;
    font-weight: bold;

    .next-button {
      font-size: 20px;
      color: #696969;
    }

    @include desktop {
      height: 80px;
    }
  }

  &.vinda-form-group {
    margin: 0;
    padding: 0;
    max-width: 100%;

    .userProfileBox {
      padding: 0 10px;
    }
  }

  @include desktop {
    max-width: 1000px;
    margin: 20px auto 50px auto;
    padding: 0;
  }
}

.userProfileButton {
  background-color: $primary-color;
  color: white;
  font-weight: bold;
  font-family: "Varela Round", sans-serif;
  margin: 40px 0 150px 0;
  padding: 10px;
  width: 80%;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  max-width: 1000px;
}

.referEarnImageBox {
  text-align: center;
}

.referEarnImage {
  width: 40%;
  text-align: center;

  @include desktop {
    width: 10%;
  }
}

.referEarnTextBox {
  text-align: center;
  max-width: 500px;
  margin: 25px auto;
  padding: 0 40px;
}

.referEarnText {
  text-align: center;
  margin: 0;
  padding: 0;
  color: black;
  // font-size: 1.2rem;
  font-weight: 900;
  font-family: "Varela Round", sans-serif;
}

.referEarnCode {
  text-align: center;
  margin: 50px 0 0 0;
  padding: 0;
  color: $primary-color;
  // font-size: 2.7rem;
  font-weight: 900;
  font-family: "Varela Round", sans-serif;
}

.referEarnButtonBox {
  text-align: center;
  margin: 50px auto 0 auto;
  padding: 0 5vw;
  width: 100%;
  max-width: 600px;
}

// ---------------------> Address <-----------------------------
.pickAddressRadioButton {
  width: 20px;
  height: 20px;
}

.pickAddressConfirmButton {
  background-color: $primary-color;
  border-radius: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
  border: 1px solid white;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "VAGRounded", sans-serif;
  width: 100%;

  @include tablet {
    font-size: 1.6rem;
  }

  @include desktop {
    font-size: 1.6rem;
  }
}

// ---------------------> Point History <-----------------------------
.pointHistoryTitleContainer {
  padding: 40px 5vw;
  width: 100%;
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/reward-desc-background.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.pointHistoryRecordHold {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 0 10px #c7c3c3;
  border-radius: 10px;
  min-height: 80px;
  font-family: "Varela Round", sans-serif;
  max-width: 800px;
}

.pointHistoryRecordDate {
  color: $primary-color;
  font-weight: bold;
  text-transform: uppercase;
}

.pointHistoryRecordItem {
  color: $main-color;
  // text-transform: capitalize;
  font-weight: bold;
  white-space: pre-line;
  line-height: 2em;
}

.pointHistoryRecordPoint {
  @include removeSpacing;
  color: green;
  font-weight: bold;
}

.pointHistorySeeMoreButton {
  background-color: $primary-color;
  color: white;
  font-weight: bold;
  font-family: "Varela Round", sans-serif;
  border: none;
  border-radius: 10px;
  width: 150px;
  height: 50px;
}

.tierBenefitsAccordionBox {
  width: 100vw;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;

  @include desktop {
    padding-left: 24rem;
    padding-right: 24rem;
    padding-bottom: 50px;
  }
}

// ---------------------> Accordion <-----------------------------

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  max-width: 30rem;
  border-radius: 0.4rem;
  overflow: hidden;
  padding-bottom: 0.6rem;
  box-shadow: 0 0 0.8rem 0.1rem rgba(darken($cAccent, 20%), 0.06),
    0 20px 30px -10px rgba(darken($cAccent, 20%), 0.2);

  &__item {
    & + & {
      border-top: 1px solid $cLightGray;
    }
  }
}

.accordionContainer {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-top: 0.9rem;
}

.accordionBox {
  background: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  -moz-box-shadow: 0 0 10px #c7c3c3;
  -webkit-box-shadow: 0 0 10px #c7c3c3;
  box-shadow: 0 0 10px #c7c3c3;
}

.accordionLeft {
  width: 15%;
}

.accordionRight {
  width: 85%;
}

.accordionIcon {
  width: 60%;
}

.accordionItem {
  $self: &;
  border-radius: 20px;
  margin-bottom: 1rem;
  box-shadow: 0 0 0.8rem 0.1rem rgba(darken($cAccent, 20%), 0.06),
    0 20px 30px -10px rgba(darken($cAccent, 20%), 0.2);

  &--opened {
    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line_open {
    display: block;
    padding: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $main-color;
    position: relative;
    border-radius: 10px;
  }

  &__line_close {
    display: block;
    padding: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-radius: 10px;
  }

  &__title_close {
    @include removeSpacing;
    color: $main-color;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: "Varela Round", sans-serif;
    text-transform: uppercase;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.2rem;

    @include tablet {
      font-size: 1.5rem;
    }

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &__title_open {
    @include removeSpacing;
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: "Varela Round", sans-serif;
    text-transform: uppercase;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.2rem;

    @include tablet {
      font-size: 1.5rem;
    }

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &__icon_open {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/minus.png");
    opacity: 0.6;
  }

  &__icon_close {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/plus.png");
    opacity: 0.6;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    position: relative;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
  }

  &__paragraph {
    margin: 0;
    font-size: 1rem;
    color: $cGray;
    font-weight: 300;
    line-height: 1.3;
    padding: 0.3rem;
    text-align: justify;

    @include tablet {
      font-size: 1.5rem;
    }

    @include desktop {
      font-size: 1.8rem;
    }
  }
}

.simpleAccordion {
  max-height: 150px;
  overflow: hidden;
  border-bottom: 2px $secondary-color solid;

  .next-button {
    transition: all 250ms;
  }

  .accordion-content {
    opacity: 0;
    max-height: 0;
    transition: all 250ms;
  }

  &.isOpen {
    transition: all 250ms;
    max-height: 5000px;

    .userProfileBox {
      background-color: #eefafe;
    }

    .next-button {
      transform: rotate(90deg);
    }

    .accordion-content {
      opacity: 1;
      max-height: 5000px;
      border: 2px $secondary-color solid;
      padding: 20px 10px;
    }
  }

  ol li,
  ul li {
    text-align: justify;
    margin: 0 0 25px 0;

    &::marker {
      font-weight: bold;
    }
  }

  ul li {
    list-style: disc;
  }

  @include desktop {
    max-height: 180px;
    transition: all 250ms;
  }

  transition: all 250ms;

  .userProfileBox {
    border: none;
    height: auto;
    padding: 20px;

    p {
      max-width: 90%;
    }
  }
}

// ---------------------> Help <-----------------------------

//---- Search Bar starts ------//
// .searchBarWrapper {
// 	width: 100%;
// 	max-width: 800px;
// 	padding: 0 5vw;
// 	margin: auto;

// 	@include desktop {
// 		padding: 0;
// 	}
// }

// .searchBar {
// 	width: 100%;
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// }

// #searchQueryInput {
// 	width: 100%;
// 	height: 2.8rem;
// 	background: #fff;
// 	outline: none;
// 	border: none;
// 	border-radius: 0.25rem;
// 	padding: 0 3.5rem 0 1.5rem;
// 	font-size: 1rem;
// 	-moz-box-shadow: 0 0 10px #c7c3c3;
// 	-webkit-box-shadow: 0 0 10px #c7c3c3;
// 	box-shadow: 0 0 10px #c7c3c3;
// }

// #searchQuerySubmit {
// 	width: 3.5rem;
// 	height: 2.8rem;
// 	font-size: 20px;
// 	margin-left: -3.5rem;
// 	background: none;
// 	border: none;
// 	outline: none;
// }

// #searchQuerySubmit:hover {
// 	cursor: pointer;
// }
//---- Search Bar ends ------//

.helpTitleContainer {
  margin: 25px auto 10px auto;
  padding: 0 5vw;
  max-width: 1000px;

  @include desktop {
    margin: 50px auto 20px auto;
  }
}

.helpTipsBox {
  margin: 0 0 40px 0;
  padding-left: 1.5rem;
  overflow-x: auto;
  overflow-y: hidden;

  @include desktop {
    justify-content: center;
  }
}

.helpTipsContain {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: $primary-color;
  width: 160px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;

  @include desktop {
    width: 200px;
    height: 100px;
    margin: 0 15px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.helpTipsBox::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.helpTipsBox {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.helpTipsTitle {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: "Varela Round", sans-serif;
  text-transform: capitalize;
  margin: auto;

  @include desktop {
    font-size: 20px;
  }
}

//---- Contact Us Box starts ------//

.helpInfoBottomBox {
  width: 100%;
  padding: 50px 5vw 150px 5vw;

  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/help-blue-bottom-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.contactUsTitle {
  @include removeSpacing;
  color: $tertiary-color;
  font-family: "Knewave", "cursive";
  text-align: left;
  padding-left: 1rem;
  font-size: 1.8rem;
}

.contactUsBox {
  padding-left: 1rem;
}

.contactUsHolder {
  padding-top: 1rem;
}

.contactUsText {
  color: #000;
  padding-top: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Varela Round", sans-serif;
  text-transform: capitalize;
  margin: auto;
}

.contactUsInfo {
  color: $tertiary-color;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Varela Round", sans-serif;
  margin: auto;
}

.socialIconBox {
  padding-right: 4rem;
  padding-left: 4rem;
}

.socialIcon {
  padding: 0.5rem;
  margin: 0 auto;
}

//---- Contact Us Box ends ------//

// ---------------------> Free Sample <-----------------------------

.pickSampleProductSlider {
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  text-align: center;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 40px 0;
  padding: 0 5vw 10px 5vw;

  .pickSampleProductWidthSet {
    flex-shrink: 0;
    width: 300px;
    height: 300px;
    margin: 0 20px;
    cursor: pointer;

    .brand-img-container {
      width: 300px;
      height: 240px;
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/free-sample-product-image-border.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include flexToCenter;
      @include removeSpacing;

      .brand-img {
        max-width: 260px;
        max-height: 190px;
        // flex-shrink: 0;
      }
    }

    .brand-name-container {
      width: 300px;
      height: 60px;
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/free-sample-product-text-border.png);
      background-repeat: no-repeat;
      background-size: 99% 100%;
      @include flexToCenter;
      @include removeSpacing;

      .dbc-title {
        color: white;
      }
    }

    @include desktop {
      margin: 0;
    }
  }

  @include desktop {
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-y: auto;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
// .pickSampleProductSlider::-webkit-scrollbar {
// 	display: none;
// }

.pickSampleModalText {
  font-family: "VAGRounded", sans-serif;
  font-weight: "bold";
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @include desktop {
    font-size: 1.5rem;
  }
}

.feedback-container {
  padding: 0 5vw;
  margin: auto;
  max-width: 650px;

  .feedbackForm {
    resize: none;
    box-shadow: inset 0 0 5px;
    border: none;
    padding: 15px;
    border-radius: 15px;
    margin: 25px 0 0 0;
    height: 300px;
    background-color: #fafafa;
  }

  .continue-button {
    color: white;
    background-color: $primary-color;
    font-weight: bold;
    width: 80%;
    margin: 40px auto;
  }

  .pickSampleQAAnswer {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 10px;
    -moz-box-shadow: 0 0 10px #c7c3c3;
    -webkit-box-shadow: 0 0 10px #c7c3c3;
    box-shadow: 0 0 10px #c7c3c3;
    color: $main-color;
    border-radius: 5px;
    // font-size: 1rem;

    &:hover,
    &.chosen {
      background-color: #b5e9ef;
    }
  }

  .tab-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 20px 0;

    .pickSampleQATabs {
      width: 80px;
      height: 10px;
      margin: 0 4px;
      background-color: #c4c4c4;

      &.current {
        background-color: $primary-color;
      }

      &.answered {
        background-color: #007e3f;
      }
    }
  }

  @include desktop {
    margin: 20px auto 50px auto;
  }
}

// ---------------------> Scan Code <-----------------------------
#manual-button {
  height: 100px;
  width: 100px;

  img.img-fluid {
    width: 100%;
    height: 100%;
  }
}

#ucForm {
  width: 80%;
  margin: auto;
}

.uc-tnc {
  font-size: 12px;
  font-weight: bold;

  @include desktop {
    font-size: 14px;
  }
}

#ucInput {
  text-align: center;
  border: none;
  height: 100%;
  width: 90%;
  color: #777;
  font-weight: bold;
  font-size: 22px;
}

#ucInput:focus {
  outline: none;
}

#ucInputContainer {
  background-image: url("https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/scan_input_box.png");
  background-repeat: no-repeat;
  background-position: center;
  // background-size: cover;
  padding: 25px;
}

#ucSubmit {
  width: 60%;
}

.scan-box {
  background-image: url("https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/border-white.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 15px 12px;

  .underline-code {
    border: 1px white solid;
    height: 1px;
    background-color: white;
  }
}

//---- Scan Code ends ------//

// ---------------------> Upload Receipt <-----------------------------
.slider-upload-receipt-banner .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-receipt-banner {
  max-width: 100%;
  border-radius: 10px;
}

.upload-receipt-content {
  @include desktop {
    width: 800px;
    margin: auto;

    .vinda-form {
      width: 100%;
    }
  }
  padding-bottom: 150px;
}

.upload-receipt-input-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #96cdec;
  color: white;
  border-radius: 10px;
  height: 180px;
  margin: 20px auto;
  width: 100%;
  border: 2px dashed #acabab;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13);
  position: relative;
}

.upload-receipt-input-file-textone {
  text-align: center;
}

//---- Upload Receipt ends ------//
// ------------------------------ ADMIN ------------------------------ //
// ----- AUTH ----- //
.admin-auth-container {
  text-align: center;
  width: 100vw;
  height: 100vh;
  @include flexToCenter;

  .admin-auth-box {
    border: 1px blue solid;
    display: flex;
    justify-content: space-between;
    max-height: 400px;

    .admin-auth-logo {
      border: 1px red solid;
      width: auto;
      max-height: 400px;
      overflow: hidden;
      padding: 30px;
    }

    .admin-auth {
      border: 1px lime solid;
      padding: 30px;
      min-height: 340px;
      background-color: white;
    }
  }
}

// ----- admin page template ----- //
.admin-page {
  display: flex;
  height: 100vh;
  width: 100vw;

  // sidebar
  #sidebar-container {
    display: flex;

    #react-pro-sidebar {
      height: 100vh;

      .pro-sidebar-header {
        height: 60px;
      }
    }
  }

  .admin-body {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    // top bar
    .navbar {
      background-color: #1d1d1d;
      height: 60px;
    }

    // MAIN CONTENT
    .admin-content {
      padding: 25px;
      overflow: auto;
    }

    // data table cells
    td {
      white-space: pre-line;
    }

    // brand dashboard
    .diaper-dashboard {
      display: grid;
      grid-template-columns: repeat(4, 250px);
      justify-content: space-around;
      row-gap: 50px;

      // brand square box
      .diaper-box {
        height: 250px;
        width: 250px;
        border: 2px #154387 solid;
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
        background-color: lighten(#154387, 50%);
        position: relative;

        .edit-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          color: black;
        }

        img {
          margin: 25px auto 0 auto;
          width: auto;
          height: 150px;
        }

        // add brand box
        &.add-diaper {
          font-size: 100px;
          color: #154387;
          @include flexToCenter;
        }
      }
    }

    // diaper details format and sizes
    #diaper-formats-sizes {
      display: flex;
      flex-wrap: wrap;

      .card {
        width: 300px;
      }
    }
  }
}

// fancy divider
#fancy-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100vw;
  margin: 0;
  padding: 0 10px;
  overflow: hidden;
  box-sizing: border-box;

  p {
    padding: 0;
    margin: 0 10px;
    text-align: center;
    font-weight: bold;
    color: $main-color;

    @include desktop {
      margin: 0 20px;
    }
  }

  .div-line {
    height: 1px;
    border: 1px $main-color solid;
    background-color: $main-color;
    flex-grow: 1;
  }

  @include desktop {
    padding: 0 4vw;
  }
}

#login-page {
  .choose-auth-container {
    display: flex;
    justify-content: center;

    .choose-auth-button {
      margin: 0 10px;
      padding: 5px 10px;
      color: white;
      border: none;
      border-radius: 6px;
      box-shadow: none;
      width: 150px;
      display: flex;
      font-weight: bolder;
      align-items: center;

      &#facebook-button {
        background-color: #417dc5;
      }

      &#google-button {
        background-color: #c73535;
      }

      .auth-icon {
        font-size: 28px;
      }
    }
  }
}

#landing-page {
  .join-button {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/button-orange-frame.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 80px 20px 20px;
    margin: 30px auto;
    text-align: center;
    color: white;
    font-weight: bold;
    display: block;
    width: fit-content;
    max-width: 80%;
    cursor: pointer;
  }

  #landing-info-section {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingInfoMobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0;
    padding: 5vh 0px;
    text-align: center;

    .info-section {
      margin: 0;
      padding: 5vh 10vw;

      h1,
      h3 {
        color: $main-color;
        font-weight: bold;
      }

      p {
        color: #696969;
        font-weight: bold;
      }

      .signup-button {
        margin: 20px 0 0 0;
        width: 300px;
      }
    }

    .interactive-section {
      margin: 50px 0;
      padding: 5vh 10vw;

      #interactive-container {
        margin: 50px 0 0 0;
        display: grid;
        // grid-template-columns: repeat(2, 1fr);

        .interactive-box-container {
          border: 1px white solid;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          position: relative;
          overflow: hidden;

          .interactive-box {
            // main image
            .interactive-image {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }

            // unrevealed overlay
            .interactive-overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              margin: 0;
              padding: 0;
              color: white;
              font-weight: bold;
              background-color: rgba($main-color, 0.8);
              opacity: 1;
              transition: all 650ms;

              // overlay image
              .overlay-image {
                width: auto;
                height: 40%;
              }

              // overlay title
              .overlay-title {
                // font-size: 16px;
                color: white;
                font-weight: bold;
                margin: 0;
                padding: 0;

                @include desktop {
                  font-size: 30px;
                }
              }

              // overlay description
              .overlay-description {
                font-size: 14px;
                margin: 0;
                padding: 0;

                @include desktop {
                  font-size: 20px;
                }
              }

              @include desktop {
                padding: 0 20%;
              }
            }
          }

          // top left token
          .interactive-token-container {
            position: absolute;
            top: 5%;
            left: 5%;
            width: 25%;
            height: 25%;
            border-radius: 50%;
            background-color: $primary-color;
            @include flexToCenter;
            opacity: 0;
            transition: all 650ms;

            .interactive-token {
              width: 60%;
              height: auto;
            }
          }

          // main bottom description
          .interactive-main-description {
            background-color: $primary-color;
            color: white;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 14px;
            width: 100%;
            min-height: 35%;
            text-align: center;
            margin: 0;
            padding: 5px 10px;
            opacity: 0;
            transition: all 650ms;
            display: flex;
            align-items: center;

            p {
              line-height: 1.2em;
            }

            @include desktop {
              padding: 20px;
              font-size: 20px;
            }
          }

          &:hover {
            .interactive-overlay {
              opacity: 0;
              transform: scale(3);
            }

            .interactive-token-container {
              opacity: 1;
            }

            .interactive-main-description {
              opacity: 1;
            }
          }
        }

        @include desktop {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    @include desktop {
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingInfo.png);
    }
  }

  #FreeSamples.auth-section {
    padding: 15vh 0 5vh;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingAuth.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .choose-auth-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .choose-auth-button {
        margin: auto;
        padding: 8px 10px;
        color: white;
        border: none;
        border-radius: 6px;
        box-shadow: none;
        width: 300px;
        font-weight: bolder;
        position: relative;

        &#facebook-button {
          background-color: #417dc5;
        }

        &#google-button {
          background-color: #c73535;
        }

        .auth-icon {
          font-size: 28px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }

    // choose sample box
    #register-sample-container {
      @include desktop {
        padding: 0 5vw;

        img.img-fluid {
          max-width: 600px;
        }
      }

      #redeem-sample-question {
        margin: 15px auto;
        padding: 15px 0px;
        width: 100%;
        color: white;
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingSampleChoice.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        font-weight: bolder;

        @include desktop {
          width: 50%;
        }

        #redeem-sample-choice {
          .redeem-sample-button {
            color: white;
            width: 140px;
            height: 40px;
            box-shadow: none;
            border: none;
            margin: 0 10px;
            border-radius: 5px;
            font-weight: bold;
            background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceDefault.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-color: transparent;

            &#yes.active {
              background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceYes.png);
            }

            &#no.active {
              background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceNo.png);
            }
          }
        }
      }
    }

    #redeem-sample-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: auto hidden;
      // justify-content: center;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;

      /* Internet Explorer 10+ */
      @include desktop {
        justify-content: center;
      }

      .redeem-sample-item {
        flex-shrink: 0;
        width: auto;
        height: 250px;
        margin: 0 10px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img-fluid {
          width: auto;
          height: 200px;
        }

        .form-check-input {
          border-radius: 50%;
          background-color: white;

          &:checked {
            background-color: $main-color;
          }
        }
      }
    }

    #redeem-sample-container::-webkit-scrollbar {
      width: 0;
      /* Remove scrollbar space */
      background: transparent;
      /* Optional: just make scrollbar invisible */
    }
  }
}

// sample container
.choice-parent {
  .choice-title {
    // font-family: "Knewave", "cursive";
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: white;
    margin: 0px 0px 5px 4vw;
    padding: 0;
  }

  .choice-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto hidden;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */

    button {
      width: 100px;
      height: 35px;
      flex-shrink: 0;
      margin: 0px 4px;
      background-color: $main-color;
      border: 2px white solid;
      border-radius: 5px;
      color: white;
      font-weight: bolder;

      @include desktop {
        margin: 0 10px;
      }
    }

    @include desktop {
      justify-content: center;
    }
  }

  .choice-container::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }
}

.FillBtn {
  width: 50%;
  margin: 15px 0 0 0;
  background-color: $warning-color;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  text-align: center;
  font-family: "VAGRounded", sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  height: 40px;
}

.NextBtn {
  width: 90%;
  margin: 15px 0 0 0;
  background-color: $primary-color;
  color: white;
  font-family: "VAGRounded", sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
}

.UpdateText {
  @include removeSpacing;
  color: $tertiary-color;
  font-size: 1.1rem;
  font-family: "VAGRounded", sans-serif;

  @include tablet {
    font-size: 1.8rem;
  }

  @include desktop {
    font-size: 1.8rem;
  }
}

// form
.vinda-form {
  margin: auto;
  padding: 0 5vw;
  width: 100%;

  .row,
  .col {
    margin: 0;
    padding: 0;
  }

  .vinda-form-group {
    margin: 10px 0px;

    // label
    .vinda-form-label {
      color: $main-color;
      font-family: "VAGRounded";
      font-weight: 900;
    }

    // input fields
    .vinda-form-input {
      border: 2px $secondary-color solid;
      background-color: white;

      &.no-border {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }

      &.no-border:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .receipt-form-input {
      width: 100%;
      font-size: 11px;
      background-color: transparent;
      display: block;
      border-radius: 6px;
      color: rgba(140, 123, 115, 1);
      border: 1px solid #c4c4c4;
      padding: 10px;
      height: 45px;
    }

    // input groups
    .input-group {
      .input-group-text {
        border: 2px $secondary-color solid;
        border-right: none;
        background-color: white;

        &.no-border {
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
        }
      }

      .vinda-form-input {
        border-left: none;
      }
    }

    // submit button
    .vinda-form-submit {
      width: 90%;
      margin: 15px 0 0 0;
      background-color: $primary-color;
      color: white;
      font-family: "VAGRounded", sans-serif;
      font-weight: bold;
      font-size: 1.2rem;
    }

    // back button
    .vinda-form-back {
      width: 90%;
      margin: 15px 0 0 0;
      background-color: $main-color;
      color: white;
      font-family: "VAGRounded", sans-serif;
      font-weight: bold;
      font-size: 1.2rem;
    }

    // agreement checkbox
    .agreement-checkbox {
      background-color: #290095;
      border-color: #80bd00;

      &:checked {
        background-color: #80bd00;
      }
    }
  }

  @include desktop {
    width: 60%;
  }
}

// content body
.content {
  margin-top: 120px;

  .top-header {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/header-bottom-body.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
    overflow: hidden;
    height: 140px;
    text-align: center;
    color: #fff000;
    font-family: "Knewave", "cursive";
    margin: 0;
    padding: 24px 0 0 0;

    @include desktop {
      display: none;
      // background-image: url(./assets/images/dashboard/desktopTopBG.png);
      // height: auto;
    }
  }

  .desktop-top-header {
    display: none;

    @include desktop {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @include desktop {
    margin-top: 140px;
  }
}

.content2 {
  margin-top: 120px;

  .top-header {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/header-bottom-body.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
    overflow: hidden;
    height: 140px;
    text-align: center;
    color: #ffffff;
    font-family: "Knewave", "cursive";
    margin: 0;
    padding: 24px 0 0 0;

    @include desktop {
      display: none;
      // background-image: url(./assets/images/dashboard/desktopTopBG.png);
      // height: auto;
    }
  }

  .desktop-top-header {
    display: none;

    @include desktop {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @include desktop {
    margin-top: 140px;
  }
}

.content3 {
  margin-top: 120px;

  .top-header {
    background-image: url("../src/assets/Mission-background.png");
    background-repeat: no-repeat;
    background-size: 100% 70%;
    background-position: left top;
    overflow: hidden;
    height: 140px;
    text-align: center;
    color: #ffffff;
    font-family: "Knewave", "cursive";
    margin: 0;
    padding: 24px 0 0 0;

    @include desktop {
      display: none;
      // background-image: url(./assets/images/dashboard/desktopTopBG.png);
      // height: auto;
    }
  }

  .desktop-top-header {
    display: none;

    @include desktop {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @include desktop {
    margin-top: 140px;
  }
}

// dashboard menu container
.dashboard-menu-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;

  // menu items
  .dashboard-menu-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    margin: 0 25px 0 0;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dashboard-menu-title {
      margin: 0;
      padding: 0;
      color: $primary-color;
      font-family: "VAGRounded", sans-serif;
      font-weight: bold;

      @include desktop {
        font-size: 20px;
      }
    }

    .dashboard-menu-icon {
      width: auto;
      height: 60px;
      margin: 10px 0 0 0;
      padding: 0;
    }

    .fitathon-image {
      animation: fadeEffect 1s steps(5, start) infinite;
      text-align: center;
    }

    @keyframes fadeEffect {
      to {
        visibility: hidden;
      }
    }
  }

  &#mobile-menu {
    display: flex;
    margin: 20px 0 0 0;
    padding: 0 2vw;

    @include desktop {
      display: none;
    }

    .dashboard-menu-item {
      justify-content: space-between;
    }
  }

  &#desktop-menu {
    display: none;

    @include desktop {
      display: flex;
      height: 50%;
    }
  }
}

// dashboard item/point shop preview
#item-preview {
  text-align: center;
  margin: 20px 0;
  padding: 0 2vw;

  .item-preview-container {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    @include desktop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .item-preview-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 25px 0;

      .item-preview-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 320px;
        height: auto;
        margin: auto;
        overflow: hidden;

        .item-preview-img {
          flex-shrink: 0;
          width: 100%;
          height: auto;
        }

        // @include desktop {
        // 	width: 320px;
        // 	height: auto;
        // }
      }

      .item-preview-text {
        color: $main-color;
        font-weight: bold;

        &.itemName {
          color: $primary-color;
          max-width: 200px;
          min-height: 50px;
          margin: auto;
        }

        &.itemPoint {
          font-family: "Knewave";
        }
      }
    }
  }

  .item-preview-button {
    border: none;
    margin: 25px auto 0 auto;
    padding: 10px;
    background-color: $primary-color;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    width: 160px;
  }

  @include desktop {
    padding: 0 5vw;
  }
}

#dashboard-goodies {
  position: relative;
  margin: 40px 0 0 0;
  padding: 15px 0 0 0;
  text-align: center;

  .blueheart {
    position: absolute;
    right: 0;
    top: -30px;

    @include desktop {
      right: 5vw;
    }
  }

  .dashboard-goodies-title {
    font-family: "Knewave", "cursive";
    color: $main-color;
    margin: 15px 0;
  }

  .dashboard-goodies-container {
    margin: 35px 0 0 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .dashboard-goodies-item {
      padding: 0 10px;

      .dashboard-goodies-image {
        width: auto;
        height: 80px;
        margin: auto;

        @include desktop {
          height: 140px;
        }
      }

      .dashboard-goodies-description {
        font-size: 12px;
        max-width: 280px;
        min-height: 60px;
        margin: 20px auto;
        padding: 10px;
        background-color: $primary-color;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

// - > footer < - //
#footer {
  width: 100vw;
  height: 65px;
  position: fixed;
  left: 0;
  bottom: 0;
  border-top: 5px darken($main-color, 10%) solid;
  box-shadow: 0px -6px 10px 2px darken($main-color, 15%);
  background-color: $main-color;
  margin: 0;
  padding: 0;
  z-index: 2;

  .mobile-footer {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 8px 0;

    .footer-item {
      text-align: center;
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .footer-icon-container {
        position: absolute;
        left: 50%;
        top: -80%;
        transform: translate(-50%, 0);
        width: 80px;
        height: 80px;
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/blue-footer-bg.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        .footer-icon {
          width: 40px;
          height: 40px;
        }
      }

      &.active .footer-icon-container {
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/orange-footer-bg.png);
      }

      .footer-label {
        font-size: 12px;
        color: $primary-color;
        font-weight: bold;
      }
    }

    @include desktop {
      display: none;
    }
  }

  .desktop-footer {
    display: none;

    @include desktop {
      display: flex;
      justify-content: space-between;
      color: $main-color;
      font-family: "Varela Round", sans-serif;
      font-weight: bold;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0 5vw 0 2vw;

      .footer-logo-container {
        @include flexToCenter;

        img.img-fluid {
          width: auto;
          height: 80px;
        }
      }

      .footer-menu-container {
        display: flex;
        width: 100%;

        .footer-menu-item {
          width: 100%;
          text-align: right;
        }
      }
    }

    .social-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $main-color;
      color: white;
      font-size: 26px;
      @include flexToCenter;
    }
  }

  @include desktop {
    position: static;
    height: 120px;
    background-color: white;
  }
}

#point-shop {
  #catalog,
  #voucher-history {
    // top selection bar
    .selection-container {
      .user-point {
        display: none;

        @include desktop {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $main-color;
          font-family: "Knewave", "cursive";
          font-weight: bold;

          img.img-fluid {
            width: 40px;
            height: 40px;
          }
        }
      }

      .categories-container {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto hidden;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        // width: 100%;

        .category {
          flex-shrink: 0;
          border: 3px $primary-color solid;
          border-radius: 10px;
          color: $primary-color;
          width: 175px;
          min-height: 40px;
          margin: 0 5px;
          padding: 5px;
          font-weight: bold;
          @include flexToCenter;
          text-align: center;

          &.active {
            background-color: $primary-color;
            color: white;
          }

          @include desktop {
            margin: 0 10px;
          }
        }

        @include desktop {
          // max-width: 60%;
          // margin-left: 45px;
        }
      }

      .categories-container::-webkit-scrollbar {
        display: none;
      }

      @include desktop {
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 5vw;
      }
    }

    // items container
    .catalog-item-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 10px;
      margin: 0;
      padding: 0;
      text-align: center;

      .catalog-item {
        .catalog-item-img-container {
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 120px;
          height: auto;
          min-height: 120px;
          margin: auto;
          overflow: hidden;

          .catalog-item-img {
            flex-shrink: 0;
            width: 100%;
            height: auto;
          }

          @include desktop {
            // width: 350px;
            height: auto;
            min-height: 350px;
          }
        }

        .catalog-item-text {
          color: $primary-color;
          font-weight: bold;

          &.itemName {
            max-width: 200px;
            min-height: 50px;
            margin: auto;
          }

          &.itemPoint {
            color: $main-color;
            font-family: "Knewave";
          }
        }
      }

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 100px;
      }
    }
  }

  #order-history-container {
    .order-history-item {
      margin: 0 20px 20px 20px;
      padding: 5px 10px;
      font-family: "Varela Round", sans-serif;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px $primary-color solid;
      border-radius: 20px;

      .info-container .order-history-orderItems {
        padding: 0 0 0 10px;
      }

      @include desktop {
        margin: 0 5vw 30px 5vw;
        padding: 10px 20px;
        flex-direction: row;
      }
    }
  }
}

#reward-detail {
  #detail-container {
    display: flex;
    flex-direction: column;

    .detail-img-container {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 350px;
      margin: auto;

      .detail-img {
        margin: auto;
        padding: 0;
        width: 80%;
        height: auto;
      }
    }

    .detail-data-container {
      margin: 0;
      padding: 0;
      width: 100%;

      // top title and points
      .detail-data {
        padding: 0 5vw;

        .detail-title {
          font-family: "Knewave", "cursive";
          color: $main-color;
        }

        .detail-point {
          display: flex;
          align-items: center;
          padding: 0 10px;

          img.img-fluid {
            width: 40px;
            height: 40px;
          }

          h3.point {
            font-family: "Varela Round", sans-serif;
            // font-family: "VAGRounded", sans-serif;
            font-weight: bold;
            color: $primary-color;
          }
        }
      }

      // bottom description
      .detail-description {
        margin: 20px 0 0 0;
        padding: 30px 20px 50px 20px;
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/rewarddescriptionbg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: $main-color;
        font-weight: bold;
        text-align: center;

        .item-description {
          white-space: pre-line;
          text-align: left;
        }

        .redeem-button {
          color: white;
          background-color: $primary-color;
          margin: auto;
          padding: 0;
          width: 60%;
          height: 40px;
          font-weight: bold;
        }

        @include desktop {
          background: none;
        }
      }

      @include desktop {
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/rewarddescriptionbg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0;
        padding: 50px 30px;
      }
    }

    @include desktop {
      flex-direction: row;
    }
  }

  #detail-desktop {
    display: none;

    @include desktop {
      display: block;
    }
  }
}

#cart-page {
  .calculation-box {
    margin: 0;
    padding: 20px;
    width: 100%;
    font-family: "VAGRounded", sans-serif;
    font-weight: bold;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/addtocartbg.png);
    background-size: 100% 100%;

    .calculation-title {
      color: $main-color;
    }

    .calculation-value {
      color: $primary-color;
    }

    @include desktop {
      width: 60%;
      margin: auto;
    }
  }

  .confirm-button {
    font-family: "VAGRounded", sans-serif;
    font-weight: bold;
    background-color: $primary-color;
    color: white;
    width: 60%;
    margin: auto;
    border-radius: 10px;
  }
}

#pick-address-page,
#baby-info {
  .pickAddressText {
    font-family: "Varela Round", sans-serif;
  }

  .pickAddressHolder {
    margin: 25px 0;
    padding: 0 5vw;
  }

  .pickAddressContainer {
    border: 3px solid $secondary-color;
    display: flex;
    line-height: 1em;
    margin: 0px auto 20px auto;
    padding: 5px 10px;
    border-radius: 10px;

    .pickAddressRadioButtonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 10px;
    }

    @include desktop {
      max-width: 80%;
    }
  }

  .confirm-button {
    font-family: "VAGRounded", sans-serif;
    font-weight: bold;
    background-color: $primary-color;
    color: white;
    width: 60%;
    margin: auto;
    border-radius: 10px;
  }
}

#add-address {
  .pickAddressText {
    font-family: "Varela Round", sans-serif;
  }
}

#order-confirm {
  .address-container {
    margin: 0;
    padding: 50px 25px;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    font-family: "Varela Round", sans-serif;

    .confirm-title {
      color: $main-color;
    }

    .button-container button.btn {
      font-family: "VAGRounded", sans-serif;
      font-weight: bold;
      background-color: $primary-color;
      color: white;
      margin: auto;
      border-radius: 10px;
    }
  }
}

#order-tracking {
  .confirm-title {
    color: $main-color;
    font-family: "Varela Round", sans-serif;
  }

  .address-container {
    margin: 0;
    padding: 30px 25px;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    font-family: "Varela Round", sans-serif;

    .button-container button.btn {
      font-family: "VAGRounded", sans-serif;
      font-weight: bold;
      background-color: $primary-color;
      color: white;
      margin: auto;
      border-radius: 10px;
    }
  }

  .tracking-content {
    margin: 30px 0;
    padding: 0;

    .address-container {
      @include desktop {
        max-height: 200px;
        background: none;
      }
    }

    .tracking-container {
      padding: 0 0 150px 0;

      @include desktop {
        padding: 0;
      }
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
      padding: 0 5vw;
    }
  }
}

#voucher-page {
  .voucher-content {
    font-family: "VAGRounded", sans-serif;

    .voucher-data-container {
      .voucher-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .voucher-image {
          width: 80%;
        }

        @include desktop {
          width: 35%;
          background-color: white;
        }
      }

      .voucher-data {
        text-align: center;
        margin: 10px 0 0 0;

        .inline-point {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $primary-color;
          font-weight: bold;

          img.img-fluid {
            width: 40px;
            height: 40px;
          }

          @include desktop {
            justify-content: flex-start;
            margin: 25px 0 0 0;
            font-size: 24px;
          }
        }

        @include desktop {
          margin: 0;
          width: 55%;
          margin: 0 0 0 50px;
          text-align: left;
          padding: 25px;

          .dbc-title {
            font-size: 26px;
          }
        }
      }

      @include desktop {
        display: flex;
        justify-content: flex-end;
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/voucdetail.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .voucher-details-container {
      margin: 30px 0 0 0;
      padding: 50px 5vw 120px 5vw;
      color: $main-color;
      text-align: center;
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/addtocartbg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .voucher-code {
        font-size: 40px;
      }

      .voucher-number-container {
        @include desktop {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
      }

      .voucher-faq {
        margin: 50px 0 0 0;

        @include desktop {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
      }

      @include desktop {
        margin: 20px 0 0 0;
        padding: 5vh 5vw 150px 5vw;
        background: none;
        display: flex;
      }
    }
  }

  button.usenow-button {
    background-color: $primary-color;
    color: white;
    width: 80%;
    font-weight: bold;
    margin: auto;

    &.mobile {
      @include desktop {
        display: none;
      }
    }

    &.desktop {
      width: 30%;
      margin: 0 5vw 0 0;
    }
  }
}

#pick-sample .freesample-tnc {
  margin: 0;
  padding: 20px 5vw 150px 5vw;
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/voucdetail.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: $main-color;

  @include desktop {
    margin: 0 25px;
  }
}

.size-master-container {
  text-align: center;
  padding: 0 0 150px 0;

  @include desktop {
    padding: 0 10vw 150px;
  }

  .img-fluid.brandImage {
    max-height: 200px;
    margin: auto;

    @include tablet {
      max-height: 300px;
    }

    @include desktop {
      max-height: 350px;
    }
  }

  .size-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 20px 0px;
    padding: 0 5vw;

    .sample-size-item {
      text-align: center;
      width: 100%;

      .sample-size-item-link {
        //div
        font-family: "Knewave";
        background-color: $main-color;
        border-radius: 10px;
        color: white;
        margin: auto;
        padding: 10px 0;
        width: 150px;
      }
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px 0px;
    }
  }
}

#sample-confirm .free-sample-content {
  .free-sample-item-container {
    height: 100%;
    background-color: white;
    text-align: center;

    .free-sample-item-image-container {
      width: 100%;
      height: 100%;
      @include flexToCenter;

      .free-sample-item-image {
        max-width: 400px;
      }
    }
  }

  .free-sample-address-container {
    margin: 25px 0 0 0;
    padding: 50px 25px 0 25px;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    font-family: "Varela Round", sans-serif;

    @include desktop {
      margin: 0;
      width: 100%;
      background: none;

      .button-container {
        display: none;
      }
    }
  }

  @include desktop {
    display: flex;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 0 0 5vw;
  }
}

#collect-points {
  .collect-points-content {
    margin: 20px 0;
    padding: 0 5vw;

    .collect-points-item {
      display: flex;
      align-items: center;
      color: $main-color;
      margin: 10px 0;
      padding: 10px;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0 0 10px #c7c3c3;
      font-family: "Varela Round", sans-serif;

      .collect-points-img-container {
        width: 60px;
        height: 60px;
        margin: 0 20px 0 0;
        @include flexToCenter;

        @include desktop {
          width: 200px;
          height: 200px;
          margin: 0;
        }
      }

      @include desktop {
        width: 300px;
        height: 300px;
        flex-direction: column;
        justify-content: center;
        margin: 0;
      }

      &.mobile {
        @include desktop {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @include desktop {
          display: flex;
        }
      }
    }

    @include desktop {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
    }
  }
}

#contact-section {
  text-align: center;
  color: $main-color;

  .contact-box-container {
    margin: 20px 0 0 0;

    .contact-box {
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/contactBox.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 20px 10px;

      .contact-img-container {
        width: 80px;
        height: auto;
      }

      .contact-description-container {
        color: white;
        font-weight: bold;
        margin: 10px 0 0 0;

        .contact-link {
          color: $main-color;
          text-decoration: underline;
        }
      }

      @include desktop {
        padding: 40px 10px;
      }
    }

    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 50px;
      padding: 0 5vw 150px 5vw;
    }
  }
}

#verification-page {
  background: $main-color;
  color: white;
  text-align: center;

  #verification-message-container {
    margin: 25% auto 0;
    padding: 0 5vw;
    max-width: 1000px;

    .verification-success {
      font-size: 100px;
      font-weight: bold;
      color: #009200;
    }

    @include desktop {
      margin-top: 10%;
    }
  }
}

.admin-date .react-datepicker__input-container {
  border: 1px solid rgb(0, 0, 0);
}

.admin-date {
  margin: 30px;
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 40px;
  // border: 0px rgb(0, 0, 0) solid;

  padding-bottom: 3px;
  padding-top: 3px;
}

.admin-date > div {
  flex: 1 1 auto;
  // border: 1px rgb(0, 0, 0) solid;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  /* and that, will result in a 10px gap */
}

.admin-date .mr-2 {
  margin-right: 5px;
}

// .false {
// 	display: inline !important;
// }
#products-info-page {
  .products-selections-container {
    display: flex;
    overflow: auto hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    margin: 25px 0;
    padding: 0;

    .products-selection {
      border: 3px $primary-color solid;
      border-radius: 10px;
      flex-shrink: 0;
      color: $primary-color;
      font-weight: bold;
      min-width: 200px;
      height: 50px;
      margin: 0 10px;
      box-shadow: none;
    }

    @include desktop {
      justify-content: center;
    }
  }

  .products-info-container {
    margin: 25px 0;
    padding: 0 5vw;

    .products-info-images-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .products-info-image {
        @include flexToCenter;

        .img-fluid {
          max-width: 150px;

          @include desktop {
            max-width: 300px;
          }
        }
      }

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

#forgot-password-page,
#reset-password-page {
  .forgot-password-container {
    margin: 25px auto 100px;
    padding: 0 5vw;
    max-width: 800px;
  }
}

.campaign-title {
  font-family: "Knewave", "cursive";
  color: #223c96;
  margin: 0 5vw;
  // display: none;

  // @include desktop {
  // 	display: block;
  // }
}

.campaign-sub-title {
  font-family: "VAGRounded", sans-serif;
  color: black;
  font-weight: bold;
  margin: 30px 5vw;
  // display: none;

  // @include desktop {
  // 	display: block;
  // }
}

.campaign-desktop-activity-container {
  display: none;
  grid-template-columns: 600px 600px;
  row-gap: 20px;
  margin: 0 5vw 120px;
  justify-content: center;

  .campaign-desktop-activity-box {
    margin: 10px auto;
    font-family: "VAGRounded", sans-serif;
    font-weight: bold;

    .campaign-desktop-activity-box-top {
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/free-sample-product-image-border.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      width: 410px;
      padding: 5px;

      .campaign-desktop-activity-image {
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/news_frame.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 30px;
        width: 400px;
        display: inline-block;
        height: 400px;
      }
    }

    .campaign-desktop-activity-box-bottom {
      background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/free-sample-product-text-border.png);
      background-size: 99% 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 10px 20px 30px;
      text-align: center;
      margin: 0 auto;
      width: 410px;
      color: white;
    }
  }

  @include desktop {
    display: grid;
  }
}

.campaign-mobile-activity {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/clouds.png),
    url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/grey-bg.png);
  background-size: cover, 100% 100%;
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  padding: 50px 0 150px;
  margin-top: 20px;

  .campaign-mobile-activity-container {
    display: grid;
    justify-content: center;
    font-family: "VAGRounded", sans-serif;

    .campaign-mobile-activity-block {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .campaign-mobile-activity-box {
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/white-box.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 12%;
        position: relative;
        height: fit-content;

        .campaign-mobile-activity-image {
          width: 100%;
          height: 400px;
        }
      }

      .campaign-mobile-activity-number {
        font-weight: bold;
        color: #ff8c00;
        margin: 10px 0 0;
      }

      .campaign-mobile-activity-description {
        font-weight: bold;
        color: #003aad;
        margin: 0 0 0;
      }

      .campaign-mobile-activity-period {
        font-weight: bold;
        color: #003aad;
      }
    }
  }

  @include desktop {
    display: none;
  }
}

.campaign-mobile-activity-box-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10% 15%;

  .campaign-mobile-activity-blur-topright-image {
    width: 30%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(40%, -40%);
  }

  .campaign-mobile-activity-blur-center-image {
    width: 90%;
  }

  .campaign-mobile-activity-blur-center-text {
    font-family: "Knewave", "cursive";
    color: #fea30b;
  }
}

.activity-header {
  position: relative;
  height: fit-content;
  margin-top: 20px;

  .activity-header-container {
    width: 75%;
    margin: auto;

    .activity-header-image {
      width: 70%;
      height: auto;
      display: block;
      margin: auto;
    }
  }

  .activity-header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.campaign-activity-desc {
  margin: 5px auto;
  font-family: "VAGRounded", sans-serif;
  font-weight: bold;
  font-size: 0.8em;
  color: #003aad;
  width: 85%;
  text-align: center;
  word-wrap: break-word;
  white-space: pre-line;

  @include desktop {
    font-size: 1.5rem;
  }
}

.campaign-activity-prize {
  border-radius: 10px;
  background-color: #beeff4;
  padding: 5px 10px;
  font-family: "VAGRounded", sans-serif;
  font-weight: bold;
  font-size: 0.67em;
  color: #003aad;
  margin: 0 auto;
  width: 85%;
  text-align: center;
  white-space: pre-line;

  @include desktop {
    font-size: 1.5rem;
  }
}

.activity-content-blue-background {
  width: 100%;
  height: 250px;
  margin-top: 30px;
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

.activity-content {
  background-color: #a6dbe7;
  margin: -200px 0 0;
  padding: 0 0 28%;
  font-family: "VAGRounded", sans-serif;

  @include desktop {
    margin: -180px 0 -9%;
  }

  .activity-content-title {
    font-family: "Knewave", "cursive";
    color: #223c96;
    margin: 0 6vw;

    @include desktop {
      text-align: center;
    }
  }

  .activity-content-prize-to-win {
    display: grid;
    grid-template-columns: 10% 80%;
    gap: 5px;
    margin: 15px 5vw 0;
  }

  .activity-content-prize-consolation {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5px;
    text-align: center;
  }

  .activity-content-prize-to-win-image {
    width: 100%;
    max-width: 50px;
  }

  .activity-content-first-prize-to-win-image {
    width: 50px;
    margin: 15px 5vw 0;
    position: absolute;
    top: 0;
    left: 0;

    @include desktop {
      left: -50px;
    }
  }

  .activity-content-prize-to-win-text {
    color: #ffa103;
    font-weight: bold;
    white-space: pre-line;

    @include desktop {
      text-align: center;
      font-size: 1.5rem;
    }

    .activity-content-prize-to-win-text-small {
      font-size: 1rem;

      @include desktop {
        font-size: 1.2rem;
      }
    }
  }

  .activity-content-prize-first-title {
    color: #f0eb23;
    text-shadow: -3px -4px 0 #1d3b92, 0 -4px 0 #1d3b92, 4px -4px 0 #1d3b92,
      4px 0 0 #1d3b92, 4px 4px 0 #1d3b92, 0 4px 0 #1d3b92, -4px 4px 0 #1d3b92,
      -4px 0 0 #1d3b92, -2px -2px 0 #1d3b92, 0 -2px 0 #1d3b92,
      -4px -4px 0 #1d3b92, 4px 0 0 #1d3b92, 2px 4px 0 #1d3b92, 0 4px 0 #1d3b92,
      4px 4px 0 #1d3b92, -2px 0 0 #1d3b92, 0 4px 0 #1d3b92, 2px -4px 0 #1d3b92,
      3px 0 0 #1d3b92, 4px 2px 0 #1d3b92, 0 4px 0 #1d3b92, 4px 0 0 #1d3b92,
      4px -2px 0 #1d3b92, -2px 4px 0 #1d3b92, -4px -2px 0 #1d3b92;
    font-weight: bold;
    margin: 0 calc(5vw + 55px);

    @include desktop {
      margin: 0 auto;
    }
  }

  .activity-content-prize-title {
    color: #f0eb23;
    text-shadow: -3px -4px 0 #1d3b92, 0 -4px 0 #1d3b92, 4px -4px 0 #1d3b92,
      4px 0 0 #1d3b92, 4px 4px 0 #1d3b92, 0 4px 0 #1d3b92, -4px 4px 0 #1d3b92,
      -4px 0 0 #1d3b92, -2px -2px 0 #1d3b92, 0 -2px 0 #1d3b92,
      -4px -4px 0 #1d3b92, 4px 0 0 #1d3b92, 2px 4px 0 #1d3b92, 0 4px 0 #1d3b92,
      4px 4px 0 #1d3b92, -2px 0 0 #1d3b92, 0 4px 0 #1d3b92, 2px -4px 0 #1d3b92,
      3px 0 0 #1d3b92, 4px 2px 0 #1d3b92, 0 4px 0 #1d3b92, 4px 0 0 #1d3b92,
      4px -2px 0 #1d3b92, -2px 4px 0 #1d3b92, -4px -2px 0 #1d3b92;
    margin: 0 auto;
    font-weight: bold;
  }

  .activity-content-prize-number {
    color: white;
    text-shadow: -1px -1px 0 #1d3b92, 0 -1px 0 #1d3b92, 1px -1px 0 #1d3b92,
      1px 0 0 #1d3b92, 1px 1px 0 #1d3b92, 0 1px 0 #1d3b92, -1px 1px 0 #1d3b92,
      -1px 0 0 #1d3b92, -1px -2px 0 #1d3b92, 0 -2px 0 #1d3b92,
      2px -2px 0 #1d3b92, 2px 0 0 #1d3b92, 2px 2px 0 #1d3b92, 0 2px 0 #1d3b92,
      -2px 2px 0 #1d3b92, -2px 0 0 #1d3b92, -2px -1px 0 #1d3b92,
      -2px 1px 0 #1d3b92, 2px 1px 0 #1d3b92, 2px -1px 0 #1d3b92,
      -1px 2px 0 #1d3b92, 1px -2px 0 #1d3b92, 1px 2px 0 #1d3b92;
  }

  .activity-content-prize-detail {
    color: #1e3a8f;
    font-weight: bold;
    margin: 10px 6vw 0;
  }

  .activity-content-image {
    width: 100%;
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/clouds-t-shirt.png);
    background-position: bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .activity-content-image-box {
    display: flex;
    // grid-template-columns: 45% 45%;
    // column-gap: 10%;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }

  .activity-content-prize-box {
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    width: 100%;
    max-width: 50%;

    &.main-prize {
      justify-content: center;
    }

    &.consolation-prize {
      justify-content: flex-start;
    }

    .my-prize-container {
      width: 100%;

      @include desktop {
        width: 60%;
      }
    }
  }

  // .activity-content-prize-box.main-prize {
  // 	flex-grow: 1;
  // 	border: 1px red solid;
  // }

  // .activity-content-prize-box.consolation-prize {
  // 	// flex-shrink: 1;
  // 	width: 40%;
  // 	border: 1px blue solid;
  // }

  .activity-content-first-prize-image {
    width: 100%;
    margin: 10px auto;

    // border: 1px red solid;
    @include desktop {
      width: 80%;
    }
  }

  .activity-content-consolation-prize-image {
    width: 95%;
    margin: 10px auto;

    // border: 1px blue solid;
    @include desktop {
      width: 75%;
    }
  }

  .activityTwoPrizeImg {
    width: 100%;
    margin: auto;

    @include desktop {
      width: 50%;
    }
  }

  // .activity-content-consolation-prize {
  // 	text-align: right;
  // }

  .activity-content-four-prize {
    width: 85%;
  }

  .activity-content-four-consolation-prize {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .activity-content-four-prize-detail {
    color: #1e3a8f;
    font-weight: bold;
    margin: 20px 4vw;
  }

  .activity-content-term-condition {
    font-weight: bold;
    color: #223c96;
    text-align: center;
    margin-bottom: 30px;
  }

  .activity-content-step-container {
    margin: 20px 5vw;
    overflow-y: scroll;
    max-height: 40vh;
    color: #223c96;

    .activity-content-step-title {
      margin-bottom: 20px;
      font-weight: bold;

      @include desktop {
        text-align: center;
        text-transform: uppercase;
      }
    }

    .activity-content-step {
      display: grid;
      grid-template-columns: 10% 80%;
      column-gap: 3%;
      margin: 10px 0;

      .activity-content-step-number {
        background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/number-circle.png);
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .activity-content-step-text {
        font-weight: bold;
        margin-top: 3px;
        white-space: pre-line;

        .activity-content-step-text-small {
          font-weight: bold;
          font-size: 0.9rem;
        }
      }
    }
  }

  .activity-content-select-entry {
    font-family: "Knewave", "cursive";
    color: #ffa103;
    text-align: center;
  }

  .activity-content-note {
    color: #223c96;
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  .activity-content-entry-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    width: fit-content;
    margin: auto auto 45px auto;

    .activity-content-point-star-two {
      width: 30px;
      height: 30px;
    }

    .activity-content-entry-text {
      color: #ffa103;
      font-weight: bold;
      margin: auto 50px auto 18px;
    }

    .activity-content-entry-incdec-btn {
      border-radius: 50%;
      background-color: #0062c0;
      color: #a7dbe7;
      padding: 5px;
      margin: auto;
      font-size: 1.5rem;
      margin: auto 15px;
      cursor: pointer;
    }

    .activity-content-entry-num {
      font-family: "Knewave", "cursive";
      margin: auto 15px;
    }

    @include desktop {
      margin: 100px auto;
    }
  }

  .activity-content-join-btn {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceDefault.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 80%;
    max-width: 300px;
    padding: 10px;
    text-align: center;
    margin: 0 auto 35px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

.activity-content-back-btn {
  text-align: center;
  color: #223c96;
  font-weight: bold;

  @include desktop {
    display: none;
  }
}

.activity-content-baby-face {
  width: 200px;
  height: 165px;
  margin: 20px auto 0;
  display: block;
  overflow: hidden;
}

.activity-content-modal-image {
  width: 200px;
  height: 210px;
  margin: 20px auto 0;
  display: block;
  overflow: hidden;
}

.activity-content-modal-image-group {
  width: 320px;
  height: 180px;
  margin: 20px auto 0;
  display: block;
  overflow: hidden;
}

.activity-modal-title {
  text-align: center;
  font-family: "VAGRounded", sans-serif;
  font-weight: bold;
  color: #0062bf;
  margin: 15px 0;
}

.activity-modal-go-btn {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceDefault.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 80%;
  max-width: 300px;
  padding: 10px;
  text-align: center;
  margin: 50px auto 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.activity-content-point-container {
  display: grid;
  column-gap: 5px;
  grid-template-columns: auto auto auto;
  width: fit-content;
  margin: 28px auto;
  font-family: "VAGRounded", sans-serif;

  .activity-content-your-point {
    font-family: "Knewave", "cursive";
    color: #ffa103;
    margin-bottom: 0;
  }

  .activity-content-point-star {
    width: 28px;
    height: 28px;
    margin: auto;
  }

  .activity-content-point-value {
    font-family: "Knewave", "cursive";
    color: #223c96;
    margin-bottom: 0;
  }

  @include desktop {
    margin: 10px auto;
  }
}

.activity-form-container {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/grey-bg.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 20px 0 0;
  padding: 15% 5vw 20px;
  font-family: "VAGRounded", sans-serif;

  .activity-form-note {
    color: #ffa103;
    border: 1px solid #ffa103;
    border-radius: 5px;
    width: 50%;
    max-width: 400px;
    padding: 15px;
    text-align: center;
    margin: auto;
    position: relative;

    h5,
    h6 {
      font-weight: bold;
    }

    .activity-form-note-topright-image {
      width: 10%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(40%, -40%);
    }
  }

  .activity-form-title {
    text-align: center;
    color: #ffa103;
    font-weight: bold;
    margin-top: 30px;
  }

  .activity-form-group {
    margin: 30px 5vw;

    .activity-form-input-title-big {
      font-weight: bold;
      color: #223c96;
    }

    .activity-form-input-title-small {
      color: #ffa103;
      font-weight: bold;
    }

    .activity-form-input-text {
      border: 1px solid #016dc4;
      width: 80%;
      max-width: 1000px;
      background-color: transparent;
    }

    .activity-form-input-select-style {
      font-weight: bold;
      border-radius: 5px;
      border: 2px solid #0074c8;
      padding: 10px 15px;
      overflow: hidden;
      max-height: 50px;
      cursor: pointer;
    }

    .activity-form-input-select-arrow {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 15px;
      color: white;
      background-color: #0062bf;
      border-radius: 50%;
      padding: 5px;
      width: 30px;
      height: 30px;
    }

    .activity-form-input-select {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    .activity-form-input-select-option {
      font-weight: bold;
      padding: 15px 0;
      margin: 0;
    }

    .activity-form-input-select-option:hover {
      background-color: #d0d0d0;
      cursor: pointer;
    }

    .activity-form-input-file {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .activity-form-input-upload {
      padding: 5px 20px;
      border: 2px solid #016dc4;
      border-radius: 6px;
      width: fit-content;
      height: fit-content;
      position: relative;
      cursor: pointer;

      @include desktop {
        padding: 3px 14px;
      }

      .activity-form-input-upload-desktop {
        display: none;
        color: #016dc4;
        font-size: 25px;
        width: auto;
        height: auto;

        @include desktop {
          display: block;
        }
      }

      .activity-form-input-upload-mobile {
        display: block;
        color: white;
        font-size: 20px;
        border-radius: 50%;
        background-color: #0062bf;
        padding: 5px;
        width: auto;
        height: auto;

        @include desktop {
          display: none;
        }
      }
    }

    .activity-form-input-upload:active {
      background-color: #d9d9d9;
    }
  }

  .activity-upload-image-container {
    position: relative;
    width: fit-content;
    overflow: hidden;
    max-width: 50%;
    min-width: 150px;
    min-height: 150px;

    .activity-upload-image-preview {
      max-width: 100%;
      border-radius: 8px;
      filter: blur(2px);
    }

    .activity-upload-image-cover {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .activity-upload-image-cover-camera {
        width: 50%;
        max-width: 150px;
      }

      .activity-upload-image-cover-text {
        text-align: center;
        color: white;
        font-weight: bold;
      }
    }
  }
}

.activity-submit-footer {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px 0 120px;
  margin-bottom: 0;
  font-family: "VAGRounded", sans-serif;

  @include desktop {
    margin-bottom: -70px;
  }

  .activity-submit-btn {
    background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceDefault.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 80%;
    max-width: 300px;
    padding: 10px;
    text-align: center;
    display: block;
    margin: 20px auto;
    color: white;
    font-weight: bold;
    font-size: 1.75rem;
    border-width: 0px;
    background-color: transparent;
  }
}

.activity-modal-yes-btn {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingChoiceDefault.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 80%;
  max-width: 300px;
  padding: 10px;
  text-align: center;
  margin: 0 auto 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.activity-modal-no-btn {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingSampleChoice.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 80%;
  max-width: 300px;
  padding: 10px;
  text-align: center;
  margin: 0 auto 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.activity-modal-subtitle-yellow {
  font-family: "VAGRounded", sans-serif;
  color: #ffa103;
  margin: 20px 0 40px;
  text-align: center;
  font-weight: bold;
}

.activity-four-content {
  padding: 15% 5vw 120px;
  color: #223c96;

  @include desktop {
    padding: 15% 5vw 20px;
  }
}

.activity-thank-booking {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/button-background.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 10px 15px;
  color: white;
  text-align: center;
  width: fit-content;
  margin: auto auto 70px;
  position: relative;
}

.activity-thank-booking-text {
  font-weight: bold;
}

.activity-thank-booking-left {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-90%, 50%) rotate(-45deg);
}

.activity-thank-booking-right {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 40%);
}

.activity-thank-title-for-link {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  white-space: pre-line;
}

.activity-thank-link-bg {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/addtocartbg.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20px 30px;
  margin: 50px -5vw;
  text-align: center;

  @include desktop {
    padding: 5px 30px;
    .input-group {
      width: 50%;
      margin: auto;
    }
  }
}

.activity-thank-link {
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
}

#ymDivCircle {
  bottom: 100px !important;
}

#ym-notification {
  bottom: 190px !important;
}

#ymFrameHolder {
  bottom: 190px !important;
}

#ymIframe {
  height: calc(100vh - 200px) !important;
}

// ###### Wishing Tree ######

.wishing-tree-title {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.035em;
  color: #1531a1;
}

.wishing-tree-title2 {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.035em;
  color: #1531a1;
}

@media (max-width: 500px) {
  .wishing-tree-title2 {
    display: none;
  }
}

.wishing-tree-subtitle {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.035em;
  color: #1531a1;
}

.blog-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 166.7%;
  text-align: center;
  letter-spacing: 0.035em;
  color: #353535;
}

.our-journey-charity {
  font-family: "Knewave";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
}

.small-journey-text {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.journey-number {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #fea30b;
}

.date-venue-text {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #fea30b;
}

.charity-description {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #5f5f5f;
}

.charity-background {
  background-image: url(https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/backgrounds/landingAuth.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

// .branches-background {
// background-image: url(../src/assets/branches.jpg);
// background-repeat: no-repeat;
// background-size: 100% 100%;
// }

.mission-background {
  background-image: url(../src/assets/Mission-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.wishingtree-background-mobile {
  background-image: url(../src/assets/Wishingtree-mobile-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.wishingtree-background {
  background-image: url(../src/assets/Wishingtree-background.png);
  min-height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.wishingtree-bigtree {
  background-image: url(../src/assets/Tree.png);
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom;
}

@media (max-width: 700px) {
  .wishingtree-bigtree {
    background-size: 155%;
  }
}

.wishing-stick {
  background-image: url(../src/assets/Tree.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.journey-background {
  background-image: url(../src/assets/Journey-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.join-us-background {
  background-image: url(../src/assets/Join-us-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

// Wishing Tree Card

.card-container {
  flex-basis: 50%;
  margin-bottom: 15%;
}

.card-container-desktop {
  // flex-basis: 50%;
  margin-top: 155px;
  margin-bottom: 50px;
  width: 90%;
}

.card-kid {
  border: 1px solid #ddd;
  background: linear-gradient(
    180deg,
    #eaffe8 0%,
    #e0ec7a 0.01%,
    #b6d53d 51.56%,
    #0e773f 100%
  );
  border-radius: 15px;
  max-width: 140px;
  text-align: center;
  margin-top: 66px;
  padding: 14px;
  // height: 170px;
  margin-left: 20%;
}

.card-kid-desktop {
  border: 1px solid #ddd;
  background: linear-gradient(
    180deg,
    #eaffe8 0%,
    #e0ec7a 0.01%,
    #b6d53d 51.56%,
    #0e773f 100%
  );
  border-radius: 15px;
  text-align: center;
  margin: auto;
  margin-top: 100px;
  padding: 8px;
  height: 80%;
  width: 50%;
}

.card_img {
  width: 80px;
  height: 80px;
  // overflow: hidden;
  border-radius: 100%;
  margin: -55px auto 0;
}

.card_img-desktop {
  width: 150px;
  height: 150px;
  // overflow: hidden;
  border-radius: 100%;
  margin: -100px auto 0;
}

.card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_img-desktop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal_img {
  width: 100px;
  height: 100px;
  // overflow: hidden;
  border-radius: 100%;
  margin: -55px auto 0;
}
.modal_img img {
  width: 110%;
  height: 110%;
  object-fit: cover;
}
.card_info {
  padding-bottom: 0px;
}

.wisher-name {
  font-family: "Knewave";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  color: #073400;
}

.wisher-name-desktop {
  font-family: "Knewave";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #073400;
}

.wisher-wish {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  color: #000000;
}

.wisher-wish-desktop {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #000000;
  padding: 15px;
}

.donate-button {
  background: #fea30b;
  color: white;
  font-family: "VAGRounded", sans-serif;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.donate-button-desktop {
  background: #fea30b;
  color: white;
  text-align: center;
  border-radius: 6px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 80%;
  cursor: pointer;
}

.donate-button-desktop:hover {
  font-weight: bold;
}

.back-button {
  width: 90%;
  background: #b0b0b0;
  color: white;
  // font-family: "VAGRounded", sans-serif;
  // font-weight: 700;
  text-align: center;
  border-radius: 6px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back-button:hover {
  font-weight: bold;
}

.submit-button {
  background-color: rgb(254, 163, 11);
  color: white;
  padding: 8px;
  border-radius: 10px;
  border: medium none;
  font-size: 16px;
  // font-family: VAGRounded;
  text-align: center;
  width: 50%;
  height: 40px;
  margin: auto;
  cursor: pointer;
}

.submit-button:hover {
  font-weight: bold;
}

// Add Blog & Wishes

.Wishes-Dashboard-Title {
  font-family: "Knewave";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #0033a1;
}

.Wishes-Label {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
  color: #003cae;
  width: 35%;
}

.wisher-modal-title {
  font-family: "Knewave";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  color: #0062bf;
}

.wisher-modal-description {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}

.image-upload-box {
  width: 135px;
  height: 100px;
  border: 2.6402px solid #67bfff;
  border-radius: 15.8412px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 36px;
  font-weight: bold;
  color: #67bfff;
}

.image-upload-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.progress-bar {
  background-color: #fea312 !important;
}

.progress {
  border-radius: 21.3rem;
  height: 1.4rem;
}

.journey-item {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-title {
  font-family: "Knewave", "cursive";
  color: $main-color;

  &:hover {
    color: #ffa500;
    cursor: pointer;
    // background-color: #0074cb;
  }
}

.sidebar-divider {
  width: 80%;
  height: 2px;
  background-color: #0074cb;
  border-radius: 50%;
  position: relative;
  bottom: 0;
  right: -60px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.wishingtree-grid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
}

.tree-container-a {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
}

.tree-container-a .each-tree:nth-child(2) {
  margin-top: -15%;
}

.tree-container-a .each-tree:nth-child(4) {
  margin-left: -30%;
}

.tree-container-a .each-tree:nth-child(5) {
  margin-right: -225%;
}

.wishing-slider {
  padding: "10px";
}

.abe .slick-prev {
  background-image: url("../src/assets/PrevBtn.png") !important; /* replace with the path to your image */
  left: 50px !important;
  width: 50px !important;
  height: 50px !important;
  background-repeat: no-repeat !important;
  z-index: 1;
}

.abe .slick-next {
  background-image: url("../src/assets/NextBtn.png") !important; /* replace with the path to your image */
  right: 50px !important;
  width: 50px !important;
  height: 50px !important;
  background-repeat: no-repeat !important;
}

// .abe .slick-list {
// 	position: relative !important;
// z-index: -999 !important;
// }

.journey-container {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 50px;
}

@media (max-width: 700px) {
  .journey-container {
    flex-direction: row;
  }
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.comingsoon-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  text-align: center;
}

.mobile-overlay {
  position: relative;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  // z-index: 9999; /* Set a higher z-index value to ensure it appears in front of other elements */
}

.mobile-comingsoon-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* Set a higher z-index value than the overlay to make it appear in front */
  text-align: center;
}

.blog-modal {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 30px;
}

@media (max-width: 700px) {
  .blog-modal {
    width: 155px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 40px;
  }
}

.journey-size {
  width: 75%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 700px) {
  .journey-size {
    width: auto;
    display: grid;
  }
}

.join-us-img {
  height: 100%;
  margin: auto;
}

@media (max-width: 700px) {
  .join-us-img {
    height: 50px;
    margin: auto;
  }
}

.generous-point {
  font-size: 18px;
}

@media (max-width: 700px) {
  .generous-point {
    font-size: 10px;
  }
}

//Tiering

.tier-benefits {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  color: #0062bf;
  margin-bottom: 20px;
}

.tier-container {
  box-shadow: rgba(0, 0, 0, 0.31) 0px 3px 7px;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  padding: 10px;
  margin-top: 20px;
}

.tier-points-container {
  display: flex;
  margin: auto;
  justify-content: normal;
  margin-top: 20px;
}

.points-required {
  letter-spacing: 0.15px;
  color: #000000;
  text-align: left;
}
// QUEST

.yourCurrentQuest {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000000;
  text-align: left;
}

.questHeader {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #0062bf;
  text-align: left;
  margin: 0;
}

@media (max-width: 700px) {
  .questHeader {
    max-width: 150px;
  }
}

.questSubHeader {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #172938;
}

.points-range {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #172938;
}

.privileges {
  font-family: "VAGRounded", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #172938;
  margin-top: 20px;
  margin-bottom: 20px;
}

.reward-item {
  text-align: center;
  width: 100%;
  border: 1px solid grey; /* Grey border for each item */
  padding: 10px;
  // margin: 5px; /* Add margin for spacing */
}

/* Common styles */
.privileges-container {
  display: flex;
  justify-content: space-evenly;
  // padding-bottom: 20px; /* Add some padding to space out the bottom border */
  // margin-bottom: 20px; /* Add margin for spacing */
}

.reward-item img {
  width: 50px !important;
}

.reward-tier-description {
  font-family: "VAGRounded", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #172938;
}

.reward-subtier-description {
  font-family: "VAGRounded", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #172938;
}

.multiplier-text {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
}

.tier-multiplier-example {
  font-family: "VAGRounded", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #172938;
}

@media (max-width: 700px) {
  .multiplier-text {
    font-size: 12px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .tier-multiplier-example {
    font-size: 12px;
  }
}

.multiplier-container {
  display: flex;
  margin: auto;
  justify-content: center;
  // margin-top: 20px;
  border: 1px solid gray;
  padding: 30px;
}

.miltiplierImg {
  width: 60px;
}

@media (max-width: 768px) {
  .privileges-container {
    flex-direction: column; /* Display items vertically on mobile */
  }

  .reward-tier-description {
    text-align: left;
    margin-left: 30px;
  }

  .reward-subtier-description {
    text-align: left;
    margin-left: 30px;
  }

  .reward-item {
    text-align: center;
    width: 100%;
    padding: 10px;
    display: flex;
    border: none;
    border-bottom: 1px solid gray;
  }

  .multiplier-container {
    border: none;
  }

  .miltiplierImg {
    width: 40px;
  }
}
.history-container {
  background: #f6f6f6;
  border: 0.5px solid #cfcfcf;
  border-radius: 8px;
  display: flex;
  padding: 15px;
  margin-bottom: 20px;
}

.history-container > div:first-child {
  margin-right: 15px; /* Add margin to create space between image and text */
}

.membership-text {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #1f1f1f;
}

.upgrade-date-text {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #1f1f1f;
}

.tier-title {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #0062bf;
}

.tier {
  margin-top: 20px;
  margin-bottom: 20px;

  .progress-bar {
    background-color: #0062bf !important;
  }
}

.gotoQuest {
  width: 50%;
  background-color: #ffb828;
  border-radius: 10px;
  text-align: center;
  font-family: "VAGRounded";
  font-weight: bold;
  height: 32px;
  margin: auto;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.QuestSubText {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #000000;
}

.QuestMainContainer {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 100px;
}

@media (max-width: 700px) {
  .QuestMainContainer {
    display: block;
    margin: auto;
    justify-content: center;
  }
}

.QuestContainer {
  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(0, 48, 158, 0.15);
  border-radius: 15px;
  margin: 10px;
}

.QuestSmallContainer {
  display: flex;
  align-items: center;
  background-color: #f1f3f9;
  border-radius: 10px;
  // width: 100%;
  // height: 100px;
  padding: 15px;
  margin-bottom: 10px;
}

.updateYourQuest {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: left;
  color: #00309e;
  padding: 20px;
}

.questPoints {
  background: #fea30b;
  border-radius: 155px;
  width: 75px;
  height: 40px;
  margin-left: 10px;
}

@media (min-width: 700px) {
  .earnMoreQuest {
    display: none;
    margin: 0 auto;
    text-align: center;
  }
}

.modal-slider .slick-next {
  display: none !important;
}

.modal-slider .slick-prev {
  display: none !important;
}

.tier-status {
  font-family: "VAGRounded";
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #0062bf;
}

.tier-description {
  font-family: "VAGRounded";
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.navbar-tier {
  width: 20px; /* Adjust this width as needed */
  height: auto;
  margin-right: 5px;
}

/* Media query for mobile view (max-width: 767px) */
@media screen and (min-width: 767px) {
  .mod-image {
    width: 255px;
  }
  .navbar-tier {
    width: 50px;
    margin-right: 5px;
  }
}

.navbar-tier-gold {
  width: 20px; /* Adjust this width as needed */
  height: auto;
  margin-right: 5px;
}

/* Media query for mobile view (max-width: 767px) */
@media screen and (min-width: 767px) {
  .navbar-tier-gold {
    width: 50px;
    margin-right: 5px;
  }
}

.nav.nav-tabs {
  margin: auto;
  justify-content: center;
  flex-wrap: unset;
  border-bottom: none;
  text-align: center;
}

.nav-link {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: none;
  margin: 0 10px;
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #0062bf !important;
  padding: 0.5rem 0rem !important;
}

.nav-link.active {
  border-top: 2px solid white !important;
  border-left: 2px solid white !important;
  border-right: 2px solid white !important;
  border-bottom: 2px solid rgb(0, 98, 191) !important;
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #0062bf !important;
  padding: 0.5rem 0rem !important;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .nav-link {
    flex-direction: row;
    padding: 0.5rem 0rem !important;
  }

  .nav-link.active {
    border-top: 2px solid white !important;
    border-left: 2px solid white !important;
    border-right: 2px solid white !important;
    border-bottom: 2px solid rgb(0, 98, 191) !important;
    font-family: "VAGRounded";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #0062bf !important;
    padding: 0.5rem 0rem !important;
  }
}

.contest-text {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.035em;
  color: #315aff;
}

.active-btn-contest {
  padding: 10px 20px;
  background-color: #0062bf;
  color: white;
  font-weight: 700;
}

.inactive-btn-contest {
  padding: 10px 20px;
  font-weight: 700;
  margin-left: 10px;
  background-color: #d9d9d9;
}

.contest-label {
  margin: 10px 0;
  font-family: "VAGRounded";
  font-weight: 900;
  font-size: 22px;
  color: #003cae;
}

.steps-instruction {
  background: #b4f1fe !important;
  color: #003cae !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  border: 3px solid #b9cccf !important;
  width: 50% !important;
}

.steps-description {
  background: #ededed !important;
  color: #003cae !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  border: 3px solid #76b2e0 !important;
  width: 50% !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px !important;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0 !important;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  border: 3px solid #76a3d8;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 10px;
  height: 15px;
  border: solid #76b2e0;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contest-blue-input {
  border: 3px solid #67acde !important;
  border-radius: 10px !important;
}

.submit-contest {
  background-color: #fea30b;
  padding: 10px;
  width: 50%;
  color: white;
  font-weight: 700;
  font-size: 24px;
  border-radius: 5px;
}

.scan3xTitle {
  font-size: 20px;
}

.scan3xDescription {
  font-size: 16px;
  line-height: 19.2px;
}

.w-90 {
  width: 90%;
}

.close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #ebebeb;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-140 {
  margin-top: 40px;
  padding-bottom: 80px;
}

.product-table {
  margin: 10px auto;
  width: 90%;
  max-width: 940px;
}

.product-table td {
  border: 1px solid black;
}

.product-table th {
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
}

.product-table tr td:first-child {
  padding-right: 30px;
}

.product-table tr td:not(:first-child) {
  text-align: center;
  padding: 5px;
}

.clickheretable {
  color: blue;
  text-decoration: underline;
}

.custom-dropdown-toogle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fea30b;
  border-color: #fea30b;
  padding: 10px;
  color: #ffffff;
  font-family: "VAGRoundedBlack";
  font-size: 14px;
  border-radius: 5px;
}

.dropdown-item-menu {
  padding: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
}

.dropdown-item-menu.open {
  height: unset;
  opacity: 1;
}

.dropdown-item-menu-list {
  height: 50px;
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  box-sizing: content-box;
}

.dropdown-item-menu-list:not(:first-child) {
  border-top: 2px solid #223c96;
}

.dropdown-item-menu-list-img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.dropdown-item-menu-list-text-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}

.dropdown-item-menu-list-text {
  margin: 0;
  color: #092a3a;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.06px;
  word-wrap: break-word;
}

.custom-dropdown-menu {
  position: relative !important;
  transform: unset !important;
  z-index: 1;
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #ffffff;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #ffffff;
}

.table-big-title {
  background-image: url("./assets/brushBackgroundMobile.png");
  background-size: 100% 100%;
  background-position: center;
  width: auto;
  flex: 3;
  padding: 2.25% 20px;
  color: #ffffff;
  font-family: "VAGRoundedBlack";
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80%;
}

.table-big-title h3 {
  text-align: center;
  font-size: 14px;
  margin: 0;
  width: 78%;
}

.table-small-title {
  background-image: url("./assets/smallBrushBackgroundMobile.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  flex: 1;
  padding: 2% 5px;
  color: #ffffff;
  font-size: 14px;
  font-family: "VAGRoundedBlack";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 13.97px;
  height: 80%;
}

.table-small-title h3 {
  text-align: center;
  font-size: 14px;
  margin: 0;
  width: 80%;
}

.point-charts-title {
  max-width: 940px;
  margin: 0 auto;
}

.point-charts-title2 {
  max-width: 940px;
  margin: 0 auto;
}

.table-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2.5px;
  max-width: 940px;
  height: 50px;
}

.size-and-points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.size-and-points .dropdown-item-menu-list-text {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-item-menu-list-img-and-name {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  gap: 5px;
}

@media (max-width: 1200px) {
  .point-charts-title {
    display: none;
  }
  .mt-140 {
    margin-top: 0;
  }
}

@media (max-width: 475px) {
  .table-big-title h3,
  .table-small-title h3 {
    font-size: 10px;
  }
}

@media (min-width: 760px) {
  .nav-link {
    margin: 0 100px;
    padding: 0.5rem 0rem !important;
  }
  .dropdown-item-menu-list-text {
    font-size: 20px !important;
    line-height: 23.44px !important;
  }
  .custom-dropdown-toogle {
    font-size: 24px !important;
  }

  .table-big-title {
    background-image: url("./assets/brushBackground.png");
  }
  .table-small-title {
    background-image: url("./assets/smallBrushBackground.png");
  }

  .table-big-title h3,
  .table-small-title h3 {
    line-height: 23.94px;
    font-size: 24px;
  }

  .dropdown-item-menu-list {
    height: 100px;
    gap: 50px;
  }
  .table-titles {
    height: 10vh;
    max-height: 100px;
    min-height: 85px;
  }
}
